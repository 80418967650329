import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSStyleDeclarationDew } from "./CSSStyleDeclaration";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    CSSStyleDeclaration: _CSSStyleDeclarationDew().CSSStyleDeclaration
  }; ///CommonJS

  /**
   * @constructor
   * @see http://www.w3.org/TR/css3-animations/#DOM-CSSKeyframeRule
   */

  CSSOM.CSSKeyframeRule = function CSSKeyframeRule() {
    CSSOM.CSSRule.call(this || _global);
    (this || _global).keyText = "";
    (this || _global).style = new CSSOM.CSSStyleDeclaration();
    (this || _global).style.parentRule = this || _global;
  };

  CSSOM.CSSKeyframeRule.prototype = new CSSOM.CSSRule();
  CSSOM.CSSKeyframeRule.prototype.constructor = CSSOM.CSSKeyframeRule;
  CSSOM.CSSKeyframeRule.prototype.type = 8; //FIXME
  //CSSOM.CSSKeyframeRule.prototype.insertRule = CSSStyleSheet.prototype.insertRule;
  //CSSOM.CSSKeyframeRule.prototype.deleteRule = CSSStyleSheet.prototype.deleteRule;
  // http://www.opensource.apple.com/source/WebCore/WebCore-955.66.1/css/WebKitCSSKeyframeRule.cpp

  Object.defineProperty(CSSOM.CSSKeyframeRule.prototype, "cssText", {
    get: function () {
      return (this || _global).keyText + " {" + (this || _global).style.cssText + "} ";
    }
  }); //.CommonJS

  exports.CSSKeyframeRule = CSSOM.CSSKeyframeRule; ///CommonJS

  return exports;
}