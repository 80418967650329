import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _parseDew } from "./parse";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    parse: _parseDew().parse
  }; ///CommonJS

  /**
   * @constructor
   * @see https://drafts.csswg.org/cssom/#the-cssgroupingrule-interface
   */

  CSSOM.CSSGroupingRule = function CSSGroupingRule() {
    CSSOM.CSSRule.call(this || _global);
    (this || _global).cssRules = [];
  };

  CSSOM.CSSGroupingRule.prototype = new CSSOM.CSSRule();
  CSSOM.CSSGroupingRule.prototype.constructor = CSSOM.CSSGroupingRule;
  /**
   * Used to insert a new CSS rule to a list of CSS rules.
   *
   * @example
   *   cssGroupingRule.cssText
   *   -> "body{margin:0;}"
   *   cssGroupingRule.insertRule("img{border:none;}", 1)
   *   -> 1
   *   cssGroupingRule.cssText
   *   -> "body{margin:0;}img{border:none;}"
   *
   * @param {string} rule
   * @param {number} [index]
   * @see https://www.w3.org/TR/cssom-1/#dom-cssgroupingrule-insertrule
   * @return {number} The index within the grouping rule's collection of the newly inserted rule.
   */

  CSSOM.CSSGroupingRule.prototype.insertRule = function insertRule(rule, index) {
    if (index < 0 || index > (this || _global).cssRules.length) {
      throw new RangeError("INDEX_SIZE_ERR");
    }

    var cssRule = CSSOM.parse(rule).cssRules[0];
    cssRule.parentRule = this || _global;

    (this || _global).cssRules.splice(index, 0, cssRule);

    return index;
  };
  /**
   * Used to delete a rule from the grouping rule.
   *
   *   cssGroupingRule.cssText
   *   -> "img{border:none;}body{margin:0;}"
   *   cssGroupingRule.deleteRule(0)
   *   cssGroupingRule.cssText
   *   -> "body{margin:0;}"
   *
   * @param {number} index within the grouping rule's rule list of the rule to remove.
   * @see https://www.w3.org/TR/cssom-1/#dom-cssgroupingrule-deleterule
   */


  CSSOM.CSSGroupingRule.prototype.deleteRule = function deleteRule(index) {
    if (index < 0 || index >= (this || _global).cssRules.length) {
      throw new RangeError("INDEX_SIZE_ERR");
    }

    (this || _global).cssRules.splice(index, 1)[0].parentRule = null;
  }; //.CommonJS


  exports.CSSGroupingRule = CSSOM.CSSGroupingRule; ///CommonJS

  return exports;
}