import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSGroupingRuleDew } from "./CSSGroupingRule";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    CSSGroupingRule: _CSSGroupingRuleDew().CSSGroupingRule
  }; ///CommonJS

  /**
   * @constructor
   * @see https://www.w3.org/TR/css-conditional-3/#the-cssconditionrule-interface
   */

  CSSOM.CSSConditionRule = function CSSConditionRule() {
    CSSOM.CSSGroupingRule.call(this || _global);
    (this || _global).cssRules = [];
  };

  CSSOM.CSSConditionRule.prototype = new CSSOM.CSSGroupingRule();
  CSSOM.CSSConditionRule.prototype.constructor = CSSOM.CSSConditionRule;
  CSSOM.CSSConditionRule.prototype.conditionText = "";
  CSSOM.CSSConditionRule.prototype.cssText = ""; //.CommonJS

  exports.CSSConditionRule = CSSOM.CSSConditionRule; ///CommonJS

  return exports;
}