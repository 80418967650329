import { dew as _CSSRuleDew } from "./CSSRule";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule
  }; ///CommonJS

  /**
   * @constructor
   * @see http://www.w3.org/TR/css3-animations/#DOM-CSSKeyframesRule
   */

  CSSOM.CSSKeyframesRule = function CSSKeyframesRule() {
    CSSOM.CSSRule.call(this || _global);
    (this || _global).name = "";
    (this || _global).cssRules = [];
  };

  CSSOM.CSSKeyframesRule.prototype = new CSSOM.CSSRule();
  CSSOM.CSSKeyframesRule.prototype.constructor = CSSOM.CSSKeyframesRule;
  CSSOM.CSSKeyframesRule.prototype.type = 7; //FIXME
  //CSSOM.CSSKeyframesRule.prototype.insertRule = CSSStyleSheet.prototype.insertRule;
  //CSSOM.CSSKeyframesRule.prototype.deleteRule = CSSStyleSheet.prototype.deleteRule;
  // http://www.opensource.apple.com/source/WebCore/WebCore-955.66.1/css/WebKitCSSKeyframesRule.cpp

  Object.defineProperty(CSSOM.CSSKeyframesRule.prototype, "cssText", {
    get: function () {
      var cssTexts = [];

      for (var i = 0, length = (this || _global).cssRules.length; i < length; i++) {
        cssTexts.push("  " + (this || _global).cssRules[i].cssText);
      }

      return "@" + ((this || _global)._vendorPrefix || "") + "keyframes " + (this || _global).name + " { \n" + cssTexts.join("\n") + "\n}";
    }
  }); //.CommonJS

  exports.CSSKeyframesRule = CSSOM.CSSKeyframesRule; ///CommonJS

  return exports;
}