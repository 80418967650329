import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSGroupingRuleDew } from "./CSSGroupingRule";
import { dew as _CSSConditionRuleDew } from "./CSSConditionRule";
import { dew as _MediaListDew } from "./MediaList";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    CSSGroupingRule: _CSSGroupingRuleDew().CSSGroupingRule,
    CSSConditionRule: _CSSConditionRuleDew().CSSConditionRule,
    MediaList: _MediaListDew().MediaList
  }; ///CommonJS

  /**
   * @constructor
   * @see http://dev.w3.org/csswg/cssom/#cssmediarule
   * @see http://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSMediaRule
   */

  CSSOM.CSSMediaRule = function CSSMediaRule() {
    CSSOM.CSSConditionRule.call(this || _global);
    (this || _global).media = new CSSOM.MediaList();
  };

  CSSOM.CSSMediaRule.prototype = new CSSOM.CSSConditionRule();
  CSSOM.CSSMediaRule.prototype.constructor = CSSOM.CSSMediaRule;
  CSSOM.CSSMediaRule.prototype.type = 4; // https://opensource.apple.com/source/WebCore/WebCore-7611.1.21.161.3/css/CSSMediaRule.cpp

  Object.defineProperties(CSSOM.CSSMediaRule.prototype, {
    "conditionText": {
      get: function () {
        return (this || _global).media.mediaText;
      },
      set: function (value) {
        (this || _global).media.mediaText = value;
      },
      configurable: true,
      enumerable: true
    },
    "cssText": {
      get: function () {
        var cssTexts = [];

        for (var i = 0, length = (this || _global).cssRules.length; i < length; i++) {
          cssTexts.push((this || _global).cssRules[i].cssText);
        }

        return "@media " + (this || _global).media.mediaText + " {" + cssTexts.join("") + "}";
      },
      configurable: true,
      enumerable: true
    }
  }); //.CommonJS

  exports.CSSMediaRule = CSSOM.CSSMediaRule; ///CommonJS

  return exports;
}