import { dew as _CSSStyleDeclarationDew } from "./CSSStyleDeclaration";
import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSGroupingRuleDew } from "./CSSGroupingRule";
import { dew as _CSSConditionRuleDew } from "./CSSConditionRule";
import { dew as _CSSStyleRuleDew } from "./CSSStyleRule";
import { dew as _MediaListDew } from "./MediaList";
import { dew as _CSSMediaRuleDew } from "./CSSMediaRule";
import { dew as _CSSSupportsRuleDew } from "./CSSSupportsRule";
import { dew as _CSSImportRuleDew } from "./CSSImportRule";
import { dew as _CSSFontFaceRuleDew } from "./CSSFontFaceRule";
import { dew as _CSSHostRuleDew } from "./CSSHostRule";
import { dew as _StyleSheetDew } from "./StyleSheet";
import { dew as _CSSStyleSheetDew } from "./CSSStyleSheet";
import { dew as _CSSKeyframesRuleDew } from "./CSSKeyframesRule";
import { dew as _CSSKeyframeRuleDew } from "./CSSKeyframeRule";
import { dew as _MatcherListDew } from "./MatcherList";
import { dew as _CSSDocumentRuleDew } from "./CSSDocumentRule";
import { dew as _CSSValueDew } from "./CSSValue";
import { dew as _CSSValueExpressionDew } from "./CSSValueExpression";
import { dew as _parseDew } from "./parse";
import { dew as _cloneDew } from "./clone";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.CSSStyleDeclaration = _CSSStyleDeclarationDew().CSSStyleDeclaration;
  exports.CSSRule = _CSSRuleDew().CSSRule;
  exports.CSSGroupingRule = _CSSGroupingRuleDew().CSSGroupingRule;
  exports.CSSConditionRule = _CSSConditionRuleDew().CSSConditionRule;
  exports.CSSStyleRule = _CSSStyleRuleDew().CSSStyleRule;
  exports.MediaList = _MediaListDew().MediaList;
  exports.CSSMediaRule = _CSSMediaRuleDew().CSSMediaRule;
  exports.CSSSupportsRule = _CSSSupportsRuleDew().CSSSupportsRule;
  exports.CSSImportRule = _CSSImportRuleDew().CSSImportRule;
  exports.CSSFontFaceRule = _CSSFontFaceRuleDew().CSSFontFaceRule;
  exports.CSSHostRule = _CSSHostRuleDew().CSSHostRule;
  exports.StyleSheet = _StyleSheetDew().StyleSheet;
  exports.CSSStyleSheet = _CSSStyleSheetDew().CSSStyleSheet;
  exports.CSSKeyframesRule = _CSSKeyframesRuleDew().CSSKeyframesRule;
  exports.CSSKeyframeRule = _CSSKeyframeRuleDew().CSSKeyframeRule;
  exports.MatcherList = _MatcherListDew().MatcherList;
  exports.CSSDocumentRule = _CSSDocumentRuleDew().CSSDocumentRule;
  exports.CSSValue = _CSSValueDew().CSSValue;
  exports.CSSValueExpression = _CSSValueExpressionDew().CSSValueExpression;
  exports.parse = _parseDew().parse;
  exports.clone = _cloneDew().clone;
  return exports;
}