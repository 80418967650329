import { dew as _CSSRuleDew } from "./CSSRule";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule
  }; ///CommonJS

  /**
   * @constructor
   * @see http://www.w3.org/TR/shadow-dom/#host-at-rule
   */

  CSSOM.CSSHostRule = function CSSHostRule() {
    CSSOM.CSSRule.call(this || _global);
    (this || _global).cssRules = [];
  };

  CSSOM.CSSHostRule.prototype = new CSSOM.CSSRule();
  CSSOM.CSSHostRule.prototype.constructor = CSSOM.CSSHostRule;
  CSSOM.CSSHostRule.prototype.type = 1001; //FIXME
  //CSSOM.CSSHostRule.prototype.insertRule = CSSStyleSheet.prototype.insertRule;
  //CSSOM.CSSHostRule.prototype.deleteRule = CSSStyleSheet.prototype.deleteRule;

  Object.defineProperty(CSSOM.CSSHostRule.prototype, "cssText", {
    get: function () {
      var cssTexts = [];

      for (var i = 0, length = (this || _global).cssRules.length; i < length; i++) {
        cssTexts.push((this || _global).cssRules[i].cssText);
      }

      return "@host {" + cssTexts.join("") + "}";
    }
  }); //.CommonJS

  exports.CSSHostRule = CSSOM.CSSHostRule; ///CommonJS

  return exports;
}