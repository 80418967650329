import { dew as _CSSStyleSheetDew } from "./CSSStyleSheet";
import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSStyleRuleDew } from "./CSSStyleRule";
import { dew as _CSSGroupingRuleDew } from "./CSSGroupingRule";
import { dew as _CSSConditionRuleDew } from "./CSSConditionRule";
import { dew as _CSSMediaRuleDew } from "./CSSMediaRule";
import { dew as _CSSSupportsRuleDew } from "./CSSSupportsRule";
import { dew as _CSSStyleDeclarationDew } from "./CSSStyleDeclaration";
import { dew as _CSSKeyframeRuleDew } from "./CSSKeyframeRule";
import { dew as _CSSKeyframesRuleDew } from "./CSSKeyframesRule";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSStyleSheet: _CSSStyleSheetDew().CSSStyleSheet,
    CSSRule: _CSSRuleDew().CSSRule,
    CSSStyleRule: _CSSStyleRuleDew().CSSStyleRule,
    CSSGroupingRule: _CSSGroupingRuleDew().CSSGroupingRule,
    CSSConditionRule: _CSSConditionRuleDew().CSSConditionRule,
    CSSMediaRule: _CSSMediaRuleDew().CSSMediaRule,
    CSSSupportsRule: _CSSSupportsRuleDew().CSSSupportsRule,
    CSSStyleDeclaration: _CSSStyleDeclarationDew().CSSStyleDeclaration,
    CSSKeyframeRule: _CSSKeyframeRuleDew().CSSKeyframeRule,
    CSSKeyframesRule: _CSSKeyframesRuleDew().CSSKeyframesRule
  }; ///CommonJS

  /**
   * Produces a deep copy of stylesheet — the instance variables of stylesheet are copied recursively.
   * @param {CSSStyleSheet|CSSOM.CSSStyleSheet} stylesheet
   * @nosideeffects
   * @return {CSSOM.CSSStyleSheet}
   */

  CSSOM.clone = function clone(stylesheet) {
    var cloned = new CSSOM.CSSStyleSheet();
    var rules = stylesheet.cssRules;

    if (!rules) {
      return cloned;
    }

    for (var i = 0, rulesLength = rules.length; i < rulesLength; i++) {
      var rule = rules[i];
      var ruleClone = cloned.cssRules[i] = new rule.constructor();
      var style = rule.style;

      if (style) {
        var styleClone = ruleClone.style = new CSSOM.CSSStyleDeclaration();

        for (var j = 0, styleLength = style.length; j < styleLength; j++) {
          var name = styleClone[j] = style[j];
          styleClone[name] = style[name];
          styleClone._importants[name] = style.getPropertyPriority(name);
        }

        styleClone.length = style.length;
      }

      if (rule.hasOwnProperty("keyText")) {
        ruleClone.keyText = rule.keyText;
      }

      if (rule.hasOwnProperty("selectorText")) {
        ruleClone.selectorText = rule.selectorText;
      }

      if (rule.hasOwnProperty("mediaText")) {
        ruleClone.mediaText = rule.mediaText;
      }

      if (rule.hasOwnProperty("conditionText")) {
        ruleClone.conditionText = rule.conditionText;
      }

      if (rule.hasOwnProperty("cssRules")) {
        ruleClone.cssRules = clone(rule).cssRules;
      }
    }

    return cloned;
  }; //.CommonJS


  exports.clone = CSSOM.clone; ///CommonJS

  return exports;
}