import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSGroupingRuleDew } from "./CSSGroupingRule";
import { dew as _CSSConditionRuleDew } from "./CSSConditionRule";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    CSSGroupingRule: _CSSGroupingRuleDew().CSSGroupingRule,
    CSSConditionRule: _CSSConditionRuleDew().CSSConditionRule
  }; ///CommonJS

  /**
   * @constructor
   * @see https://drafts.csswg.org/css-conditional-3/#the-csssupportsrule-interface
   */

  CSSOM.CSSSupportsRule = function CSSSupportsRule() {
    CSSOM.CSSConditionRule.call(this || _global);
  };

  CSSOM.CSSSupportsRule.prototype = new CSSOM.CSSConditionRule();
  CSSOM.CSSSupportsRule.prototype.constructor = CSSOM.CSSSupportsRule;
  CSSOM.CSSSupportsRule.prototype.type = 12;
  Object.defineProperty(CSSOM.CSSSupportsRule.prototype, "cssText", {
    get: function () {
      var cssTexts = [];

      for (var i = 0, length = (this || _global).cssRules.length; i < length; i++) {
        cssTexts.push((this || _global).cssRules[i].cssText);
      }

      return "@supports " + (this || _global).conditionText + " {" + cssTexts.join("") + "}";
    }
  }); //.CommonJS

  exports.CSSSupportsRule = CSSOM.CSSSupportsRule; ///CommonJS

  return exports;
}