var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {}; ///CommonJS

  /**
   * @constructor
   * @see http://dev.w3.org/csswg/cssom/#the-stylesheet-interface
   */

  CSSOM.StyleSheet = function StyleSheet() {
    (this || _global).parentStyleSheet = null;
  }; //.CommonJS


  exports.StyleSheet = CSSOM.StyleSheet; ///CommonJS

  return exports;
}