import { dew as _CSSRuleDew } from "./CSSRule";
import { dew as _CSSStyleSheetDew } from "./CSSStyleSheet";
import { dew as _MediaListDew } from "./MediaList";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {
    CSSRule: _CSSRuleDew().CSSRule,
    CSSStyleSheet: _CSSStyleSheetDew().CSSStyleSheet,
    MediaList: _MediaListDew().MediaList
  }; ///CommonJS

  /**
   * @constructor
   * @see http://dev.w3.org/csswg/cssom/#cssimportrule
   * @see http://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSImportRule
   */

  CSSOM.CSSImportRule = function CSSImportRule() {
    CSSOM.CSSRule.call(this || _global);
    (this || _global).href = "";
    (this || _global).media = new CSSOM.MediaList();
    (this || _global).styleSheet = new CSSOM.CSSStyleSheet();
  };

  CSSOM.CSSImportRule.prototype = new CSSOM.CSSRule();
  CSSOM.CSSImportRule.prototype.constructor = CSSOM.CSSImportRule;
  CSSOM.CSSImportRule.prototype.type = 3;
  Object.defineProperty(CSSOM.CSSImportRule.prototype, "cssText", {
    get: function () {
      var mediaText = (this || _global).media.mediaText;
      return "@import url(" + (this || _global).href + ")" + (mediaText ? " " + mediaText : "") + ";";
    },
    set: function (cssText) {
      var i = 0;
      /**
       * @import url(partial.css) screen, handheld;
       *        ||               |
       *        after-import     media
       *         |
       *         url
       */

      var state = "";
      var buffer = "";
      var index;

      for (var character; character = cssText.charAt(i); i++) {
        switch (character) {
          case " ":
          case "\t":
          case "\r":
          case "\n":
          case "\f":
            if (state === "after-import") {
              state = "url";
            } else {
              buffer += character;
            }

            break;

          case "@":
            if (!state && cssText.indexOf("@import", i) === i) {
              state = "after-import";
              i += "import".length;
              buffer = "";
            }

            break;

          case "u":
            if (state === "url" && cssText.indexOf("url(", i) === i) {
              index = cssText.indexOf(")", i + 1);

              if (index === -1) {
                throw i + ": \")\" not found";
              }

              i += "url(".length;
              var url = cssText.slice(i, index);

              if (url[0] === url[url.length - 1]) {
                if (url[0] === "\"" || url[0] === "'") {
                  url = url.slice(1, -1);
                }
              }

              (this || _global).href = url;
              i = index;
              state = "media";
            }

            break;

          case "\"":
            if (state === "url") {
              index = cssText.indexOf("\"", i + 1);

              if (!index) {
                throw i + ": '\"' not found";
              }

              (this || _global).href = cssText.slice(i + 1, index);
              i = index;
              state = "media";
            }

            break;

          case "'":
            if (state === "url") {
              index = cssText.indexOf("'", i + 1);

              if (!index) {
                throw i + ": \"'\" not found";
              }

              (this || _global).href = cssText.slice(i + 1, index);
              i = index;
              state = "media";
            }

            break;

          case ";":
            if (state === "media") {
              if (buffer) {
                (this || _global).media.mediaText = buffer.trim();
              }
            }

            break;

          default:
            if (state === "media") {
              buffer += character;
            }

            break;
        }
      }
    }
  }); //.CommonJS

  exports.CSSImportRule = CSSOM.CSSImportRule; ///CommonJS

  return exports;
}