var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {}; ///CommonJS

  /**
   * @constructor
   * @see http://dev.w3.org/csswg/cssom/#the-cssrule-interface
   * @see http://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSRule
   */

  CSSOM.CSSRule = function CSSRule() {
    (this || _global).parentRule = null;
    (this || _global).parentStyleSheet = null;
  };

  CSSOM.CSSRule.UNKNOWN_RULE = 0; // obsolete

  CSSOM.CSSRule.STYLE_RULE = 1;
  CSSOM.CSSRule.CHARSET_RULE = 2; // obsolete

  CSSOM.CSSRule.IMPORT_RULE = 3;
  CSSOM.CSSRule.MEDIA_RULE = 4;
  CSSOM.CSSRule.FONT_FACE_RULE = 5;
  CSSOM.CSSRule.PAGE_RULE = 6;
  CSSOM.CSSRule.KEYFRAMES_RULE = 7;
  CSSOM.CSSRule.KEYFRAME_RULE = 8;
  CSSOM.CSSRule.MARGIN_RULE = 9;
  CSSOM.CSSRule.NAMESPACE_RULE = 10;
  CSSOM.CSSRule.COUNTER_STYLE_RULE = 11;
  CSSOM.CSSRule.SUPPORTS_RULE = 12;
  CSSOM.CSSRule.DOCUMENT_RULE = 13;
  CSSOM.CSSRule.FONT_FEATURE_VALUES_RULE = 14;
  CSSOM.CSSRule.VIEWPORT_RULE = 15;
  CSSOM.CSSRule.REGION_STYLE_RULE = 16;
  CSSOM.CSSRule.prototype = {
    constructor: CSSOM.CSSRule //FIXME

  }; //.CommonJS

  exports.CSSRule = CSSOM.CSSRule; ///CommonJS

  return exports;
}