var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  //.CommonJS
  var CSSOM = {}; ///CommonJS

  /**
   * @constructor
   * @see http://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSValue
   *
   * TODO: add if needed
   */

  CSSOM.CSSValue = function CSSValue() {};

  CSSOM.CSSValue.prototype = {
    constructor: CSSOM.CSSValue,

    // @see: http://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSValue
    set cssText(text) {
      var name = this._getConstructorName();

      throw new Error("DOMException: property \"cssText\" of \"" + name + "\" is readonly and can not be replaced with \"" + text + "\"!");
    },

    get cssText() {
      var name = this._getConstructorName();

      throw new Error("getter \"cssText\" of \"" + name + "\" is not implemented!");
    },

    _getConstructorName: function () {
      var s = (this || _global).constructor.toString(),
          c = s.match(/function\s([^\(]+)/),
          name = c[1];

      return name;
    }
  }; //.CommonJS

  exports.CSSValue = CSSOM.CSSValue; ///CommonJS

  return exports;
}